<template>
  <div>
    <div class="title"><span>PortPolio</span></div>
    <!-- Title motion -->
    <div class="container" :key="index" v-for="(object, index) in object1">
      <!-- ForEach (index, object) img + imgCover. -->
      <div
        :class="{ active: object.mobile, bx1: object.unactive }"
        @click="port1(index)"
      >
        <div class="img_ex" v-text="object.txt"></div>
        <div class="imgCover">
          <!-- <video controls loop>
            <source :src="object.video" type="video/mp4" />
            data
          </video> -->
          <img :src="object.img" />
          <div class="details">
            <p>Details</p>
            <span v-text="object.details"></span><br /><br />
            <p>Used Skills</p>
            <div class="skills">
              <div
                v-for="object1 in object.skills"
                :key="object1"
                v-text="object1"
              ></div>
            </div>
            <br />
            <p>functions</p>
            <span v-text="object.function"> </span><br /><br />
            <p>GitHub</p>
            <span v-text="object.github"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      object1: [
        {
          // video: require("../../assets/video/movietogether.mp4"),
          img: require("../../assets/images/1.png"),
          txt: "Movie Together",
          mobile: false,
          unactive: true,
          details:
            "Movie reservation program that focuses on the design of the existing megabox web",
          skills: [
            "Javascript",
            "JSP",
            "CSS",
            "HTML",
            "JAVA",
            "MySQL",
            "Apache Tomcat",
            "jQuery",
            "Github",
            "Ajax"
          ],
          function: "[Login] [Register] [Board system] [Reply]",
          github: "https://github.com/hak00/MovieTogether.git"
        },
        {
          // video: require("../../assets/video/learn&runFull.mp4"),
          img: require("../../assets/images/2.png"),
          txt: "Learn & Run",
          mobile: false,
          unactive: true,
          details:
            "Online lecture program that focuses on the design of the existing class101",
          skills: [
            "Javascript",
            "JSP",
            "CSS",
            "HTML",
            "JAVA",
            "MySQL",
            "Apache Tomcat",
            "jQuery",
            "Github",
            "Ajax",
            "Spring",
            "Mybatis",
            "Maven"
          ],
          function:
            "[Login] [Register] [Conversation System] [Reservation system]",
          github: "https://github.com/Learn-N-Run/Learn-N-Run.git"
        },
        {
          // video: require("../../assets/video/learn&runFull.mp4"),
          img: require("../../assets/images/1.png"),
          txt: "Movie Together",
          mobile: false,
          unactive: true,
          details:
            "Movie reservation program that focuses on the design of the existing megabox web",
          skills: [
            "Javascript",
            "JSP",
            "CSS",
            "HTML",
            "JAVA",
            "MySQL",
            "Apache Tomcat",
            "jQuery",
            "Github",
            "Ajax",
            "Spring",
            "Mybatis",
            "Maven"
          ],
          function: "[Login] [Register] [Board system] [Reply]"
        }
      ]
    };
  },
  methods: {
    port1(index) {
      this.object1[index].mobile = !this.object1[index].mobile;
      this.object1[index].unactive = !this.object1[index].unactive;
    },
    // port2(index) {
    //   this.object1[index].mobile = !this.object1[index].unnactive;
    //   if(this.object1[index].mobile == true)
    //     this.object1[index].mobile = !this.object1[index].mobile
    //   else 
    //     this.object1[index].unactive = null;
    //     return false;
    //     e.target.preventDefault();

    //     //Firebase login project true;;
    // }
  }
};
</script>

<style scoped>
/* 제목  */
.title {
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: Helvetica, sans-serif;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 0 20px rgba(400, 40, 43, 1);
  font-weight: bold;
  margin-bottom: 30px;
}

/* 전체 BOX */
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  margin-bottom: 10px;
}

/* 
  Click 이전 포폴 개당 BOX 
  Click 이전 : .bx1
  Click 이후 : .active
*/
.container .bx1 {
  padding: 10px 10px;
  opacity: 0.5;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
  width: 400px;
  transition: all 1.5s;
  height: 200px;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
}
.container .bx1:hover {
  padding: 30px 30px;
  font-size: 33px;
  opacity: 1;
  width: 400px;
  height: 300px;
  cursor: pointer;
}

.bx1 .imgCover img {
  max-width: 400px;
  max-height: 100%;
}

.bx1 .details {
  display: none;
}

.img_ex {
  margin-bottom: 3px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 0 20px rgba(12, 255, 43, 1);
}

/* active 이후 CSS */
.active {
  opacity: 0.5;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
  width: 80%;
  transition: all 0.1s;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
  padding: 15px 15px;
}

.active .img_ex {
  font-size: 33px;
  margin-bottom: 20px;
}

.active .imgCover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

/* .bx1 .imgCover video {
  display: none;
}

.active .imgCover img {
  display: none;
} */

.active .imgCover img {
  width: 600px;
  height: 100%;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
  padding: 10px 10px;
  margin-bottom: 30px;
}

.active .details {
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
  width: 600px;
  padding: 10px 10px;
}

.active .details p {
  font-size: 20px;
  padding: 0;
  margin: 0;
  color: cadetblue;
  text-transform: uppercase;
  font-weight: bold;
}
.active .details span {
  font-size: 18px;
  text-transform: none;
  font-weight: normal;
}

.active .details .skills {
  margin-top: 5px;
  font-size: 15px;
  text-transform: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.active .details .skills div {
  box-shadow: 0px 0px 5px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
  padding: 2px 2px;
}

.active a {
  font-size: 13px;
  text-decoration: none;
  color: white;
}

.active:hover {
  opacity: 1;
}

@media (max-width: 869px) {
  .active .imgCover img {
    width: 500px;
  }
  .active .details {
    width: 500px;
  }
}

@media (max-width: 738px) {
  .active .imgCover img {
    width: 400px;
  }
  .active .details {
    width: 400px;
  }
}
@media (max-width: 612px) {
  .active .img_ex {
    font-size: 27px;
  }
  .active .imgCover video {
    width: 300px;
  }
  .active .details {
    width: 300px;
  }
  .active .details p {
    font-size: 16px;
  }
  .active .details span {
    font-size: 14px;
  }
  .active .details .skills div {
    font-size: 14px;
  }
}

@media (max-width: 523px) {
  .bx1 .imgCover img {
    max-width: 300px;
  }
  .container .bx1:hover {
    padding: 20px 10px;
  }
}

@media (max-width: 484px) {
  .active .img_ex {
    font-size: 23px;
  }
  .active .imgCover img {
    width: 200px;
  }
  .active .details {
    width: 200px;
  }
  .active .details span {
    display: inline-block;
  }
  .active .details span:nth-last-child(1) {
    word-break: break-all;
  }
}

@media (max-width: 420px) {
  .bx1 .imgCover img {
    max-width: 250px;
  }
  .container .bx1:hover {
    font-size: 25px;
    padding: 20px 20px;
  }
}
</style>
